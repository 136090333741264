import { useTranslated, type Locale } from "@i18next/utils";
import React from "react";
import { Accordion, AccordionItem } from "@nextui-org/accordion";
import Link from "@react/widget/Link";
import Icon from "@react/widget/Icon";

interface Props {
  currentSite: Page.Site;
  data?: Page.Navigation;
  className?: string;
}

const FooterAccordion: React.FC<Props> = ({ currentSite, data, ...props }) => {
  const { t, tr } = useTranslated(currentSite.locale);
  return !!data?.children ? (
    <dl {...props}>
      <dt className="border-split-light border-b py-3 font-medium">{t(data.title)}</dt>
      <dd>
        <Accordion
          className="accordion w-full text-sm text-white px-0"
          defaultExpandedKeys={["accordion-item-0"]}
          itemClasses={{
            base: "border-split-light border-b px-0",
            trigger: "py-3 font-medium",
            title: "text-sm text-white font-medium tracking-wide",
            content: "py-2.5 grid gap-y-2.5",
            indicator: "data-[open=true]:rotate-180"
          }}
        >
          {data.children.map((_nav, i) => (
            <AccordionItem
              key={`accordion-item-${i}`}
              aria-label={`Accordion ${i}`}
              title={_nav.title}
              indicator={<Icon className="text-sm" name="chevron-down"/>}
            >
              <ul className="pb-2.5 grid gap-y-2.5">
                {_nav.children?.map((_item, i) => _item.element?.status !== "disabled" && <li key={`accordion-item-li-${i}`}><Link href={tr(_item.uri)}>{_item.title}</Link></li>)}
              </ul>
            </AccordionItem>
          ))}
        </Accordion>
      </dd>
    </dl>
  ) : <></>;
};

export default FooterAccordion;